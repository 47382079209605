import { uiContext } from '../../contexts/ui'
import { defaultRemarkFilters } from './Remarks.types'
import { RemarksLegend } from './components/RemarksLegend'
import { RemarksTable } from './components/RemarksTable'
import { CurrentSortableColumn } from './components/RemarksTable'
import { Stack } from '@mui/material'
import { RemarksFilters } from 'api/remarks/types'
import { useBreadcrumbProjectLink } from 'hooks/useBreadcrumbProjectLink'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { MouseEvent, useCallback } from 'react'
import { FC, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const Remarks: FC = () => {
  const cUI = useContext(uiContext)
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { role } = useTypedSelector(profileSelector)
  const navigate = useNavigate()
  // const [projectId, setProjectId] = useState(projectIdParams)
  const accessToAdd = role === 'admin' || role === 'engineer_qc'

  const onMenuItemClickBefore = useCallback(() => {
    setSortableColumn(null)
    setFilterData(defaultRemarkFilters)
  }, [])

  const { onProjectBreadClick, project, isFetching, ProjectsMenu, isMenuOpen } = useBreadcrumbProjectLink({
    projectId,
    navigateUrl: '/project/{projectId}/remarks',
    onMenuItemClickBefore,
  })

  // Location
  const location = useLocation()
  const locationState = location.state as any
  const { sortableColumn: locationSortableColumn, filterData: locationFilterData } = locationState || ({} as any)

  const [sortableColumn, setSortableColumn] = useState<CurrentSortableColumn | null>(locationSortableColumn || null)
  const [filterData, setFilterData] = useState<RemarksFilters>(locationFilterData || defaultRemarkFilters)

  useLayoutEffect(() => {
    cUI.setShowSearch(false)
    return () => {
      cUI.setShowSearch(true)
    }
  }, [])

  // Clearing location state after page refresh
  useEffect(() => {
    if (locationState && locationState.transaction) {
      let state: Partial<any> = {}
      if (typeof locationState === 'object') state = { ...(locationState || {}), replace: true }
      delete state.transaction
      navigate(location.pathname, state)
    }
  }, [])

  useBreadcrumbs(
    [
      { title: 'Проекты' },
      {
        title: project?.shortName!,
        onClick: (e) => onProjectBreadClick(e),
        MenuOpen: isMenuOpen,
        type: 'menu',
      },
      { title: 'Замечания' },
    ],
    [isMenuOpen, project],
    isFetching,
  )

  return (
    <Stack flex={1} maxWidth='100%'>
      <RemarksLegend
        accessToAdd={accessToAdd}
        createButtonData={{
          text: 'Создать замечание',
          href: `/project/${projectId}/remarks/add`,
          hrefState: { state: { sortableColumn, filterData } },
        }}
        columnSettings='remarks'
        sortableColumn={null}
        filterData={null}
      />

      <RemarksTable
        projectId={projectId}
        key={projectId}
        sort={{ sortableColumn, setSortableColumn }}
        filter={{ filterData, setFilterData }}
      />

      {ProjectsMenu}
    </Stack>
  )
}
