import { RootState } from 'store'

export const regulationsShouldResetPageSelector = (state: RootState): boolean =>
  state.infiniteScroll.shouldResetPage.regulations

export const ksgShouldResetPageSelector = (state: RootState): boolean => state.infiniteScroll.shouldResetPage.ksg

export const documentsShouldResetPageSelector = (state: RootState): boolean =>
  state.infiniteScroll.shouldResetPage.documents

export const remarksShouldResetPageSelector = (state: RootState): boolean =>
  state.infiniteScroll.shouldResetPage.remarks

export const prescriptionsShouldResetPageSelector = (state: RootState): boolean =>
  state.infiniteScroll.shouldResetPage.prescriptions
