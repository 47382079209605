import { Reference, basicReferences } from '../References.types'
import { Defects } from './Defects'
import { ProjectMembers } from './ProjectMembers'
import { Content, ReferenceTableWrapper } from './ReferenceContent.styles'
import { DrawerData } from './ReferenceContent.types'
import { EditAndAddDrawer } from './ReferenceDrawers/EditAndAddDrawer'
import { Representatives } from './Representatives'
import { Table as ReferenceTable } from './Table'
import { Stack } from '@mui/material'
import {
  useClearReferenceMutation,
  useLazyExportReferenceByProjectQuery,
  useLazyExportReferenceQuery,
  useLazyGetReferenceExportResultByProjectQuery,
  useLazyGetReferenceExportResultQuery,
} from 'api/references'
import { TableManagment } from 'components/TableManagment'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { downloadMedia } from 'utils/downloadMedia'
import { ImportDrawer } from './ReferenceDrawers/ImportDrawer'

export const ReferenceContent: FC = () => {
  const { projectId: projectIdString, referenceId } = useParams()
  const projectId = Number(projectIdString)
  const isProjectMode = !!projectId
  const navigate = useNavigate()
  const [clearReference, clearReferenceResponse] = useClearReferenceMutation()
  const [drawerData, setDrawerData] = useState<DrawerData>({
    isEditAndAddDrawerOpen: false,
    isImportDrawerOpen: false,
  })
  const { role } = useTypedSelector(profileSelector)
  const { enqueueSnackbar } = useSnackbar()

  const [exportReference, exportReferenceResult] = useLazyExportReferenceQuery()
  const [getReferenceExportResult, referenceExportResult] = useLazyGetReferenceExportResultQuery()

  const [exportReferenceByProject, exportReferenceResultByProject] = useLazyExportReferenceByProjectQuery()
  const [getReferenceExportResultByProject, referenceExportResultByProject] =
    useLazyGetReferenceExportResultByProjectQuery()

  const currentReference: Reference = useMemo(() => {
    return basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0]
  }, [referenceId])

  const viewingOnly: boolean = role === 'engineer_qc' && currentReference.id === 'DEFECT'

  const onClearReference = useCallback(() => {
    clearReference({
      url: projectId
        ? currentReference.api.clear.projectUrl.replace('{project}', String(projectId))
        : currentReference.api.clear.url,
    })
  }, [])

  const onDrawerOpen = useCallback((drawerType: keyof typeof drawerData) => {
    setDrawerData((prev) => ({ ...prev, [drawerType]: true }))
  }, [])

  const onDrawerClose = useCallback((drawerType: keyof typeof drawerData) => {
    setDrawerData((prev) => ({ ...prev, [drawerType]: false }))
  }, [])

  const onReturnClick = useCallback(() => {
    projectId ? navigate(`/project/${projectId}/references`) : navigate(`/administration/references`)
  }, [projectId])

  const refactoredReferences = ['PROJECT_MEMBER', 'PROJECT_MEMBER_REPRESENTATIVE', 'DEFECT']

  const onExportClick = () => {
    if (!currentReference.api.export) return

    const localUrl = projectId
      ? currentReference.api.export.projectUrl.replace('{project}', String(projectId))
      : currentReference.api.export.url

    isProjectMode ? exportReferenceByProject({ url: localUrl, projectId }) : exportReference({ url: localUrl })
  }

  useMutationHandlers(isProjectMode ? exportReferenceResultByProject : exportReferenceResult, (data) => {
    if (!data.success) {
      enqueueSnackbar(data.description, { variant: 'error' })
      return
    }

    if (!currentReference.api.getExportResult) return

    const localUrl = projectId
      ? currentReference.api.getExportResult.projectUrl.replace('{project}', String(projectId))
      : currentReference.api.getExportResult.url

    isProjectMode
      ? getReferenceExportResultByProject({ url: localUrl, projectId, id: data.data })
      : getReferenceExportResult({ url: localUrl, id: data.data })
  })

  useMutationHandlers(isProjectMode ? referenceExportResultByProject : referenceExportResult, (data) => {
    downloadMedia(data.link)
    enqueueSnackbar('Файл успешно сгенерирован.', { variant: 'success' })
  })

  useMutationHandlers(clearReferenceResponse, () => {
    enqueueSnackbar('Справочник успешно очищен.', { variant: 'success' })
  })

  return (
    <>
      {currentReference.id === 'PROJECT_MEMBER' && <ProjectMembers />}
      {currentReference.id === 'PROJECT_MEMBER_REPRESENTATIVE' && <Representatives />}
      {currentReference.id === 'DEFECT' && <Defects />}

      {!refactoredReferences.includes(currentReference.id) && (
        <Content>
          <Stack paddingRight={'20px'}>
            <TableManagment
              show={!currentReference.doubleRow}
              onReturnClick={onReturnClick}
              onClearClick={onClearReference}
              onImportClick={!viewingOnly ? () => onDrawerOpen('isImportDrawerOpen') : undefined}
              onAddClick={!viewingOnly ? () => onDrawerOpen('isEditAndAddDrawerOpen') : undefined}
              onExportClick={currentReference.id === 'ASSIGNMENT_TYPE' ? onExportClick : undefined}
              buttonManagmentTitle='Управление'
            />
          </Stack>

          <ReferenceTableWrapper>
            <ReferenceTable viewingOnly={viewingOnly} />
          </ReferenceTableWrapper>

          <EditAndAddDrawer
            isOpen={drawerData.isEditAndAddDrawerOpen}
            onClose={() => onDrawerClose('isEditAndAddDrawerOpen')}
          />

          <ImportDrawer isOpen={drawerData.isImportDrawerOpen} onClose={() => onDrawerClose('isImportDrawerOpen')} />
        </Content>
      )}
    </>
  )
}
