import {
  IClearDefectsByProjectRequest,
  ICreateDefectByProjectRequest,
  ICreateDefectByProjectResponse,
  ICreateDefectRequest,
  ICreateDefectResponse,
  IDeleteDefectByProjectRequest,
  IDeleteDefectRequest,
  IEditDefectByProjectRequest,
  IEditDefectByProjectResponse,
  IEditDefectRequest,
  IEditDefectResponse,
  IExportDefectsByProjectRequest,
  IExportDefectsByProjectResponse,
  IExportDefectsResponse,
  IGetDefectsByProjectRequest,
  IGetDefectsByProjectResponse,
  IGetDefectsExportResultByProjectRequest,
  IGetDefectsExportResultByProjectResponse,
  IGetDefectsRequest,
  IGetDefectsResponse,
  IGetDropdownAssignmentTypesByProjectRequest,
  IGetDropdownAssignmentTypesByProjectResponse,
  IGetDropdownAssignmentTypesResponse,
  IGetExportDefectsExportResultRequest,
  IGetExportDefectsExportResultResponse,
} from './api.types'
import { api } from 'api/api'
import { getTimestamp } from 'utils/dates/getTimestamp'

export const defectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    // Admin mode
    getDefects: build.query<IGetDefectsResponse, IGetDefectsRequest>({
      query: ({ ...params }) => {
        return {
          method: 'GET',
          url: `/defect/list`,
          params: params,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query

        const otherArgsChanged = queryChanged

        if (currentArg && otherArgsChanged) currentArg.page = 1

        return pageChanged || otherArgsChanged
      },
      providesTags: ['References', { type: 'References', id: 'DEFECT' }],
    }),
    createDefect: build.mutation<ICreateDefectResponse, ICreateDefectRequest>({
      query: ({ body, ...params }) => ({
        url: '/defect/create',
        method: 'POST',
        params,
        body,
      }),
      async onQueryStarted({ body, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { data: newItem, index } = data || {}

          dispatch(
            defectsApi.util.updateQueryData('getDefects', {}, (draft) => {
              if (typeof index !== 'number') return

              if (index >= 0 && index <= draft.data.length) {
                draft.data.splice(index, 0, newItem)
              }
            }),
          )
        } catch {}
      },
    }),
    editDefect: build.mutation<IEditDefectResponse, IEditDefectRequest>({
      query: ({ defectId, body }) => ({
        url: `/defect/${defectId}/update`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ defectId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { data: updatedItem, index } = data || {}

          dispatch(
            defectsApi.util.updateQueryData('getDefects', {}, (draft) => {
              const updatedItemIndex = draft.data.findIndex((item) => item.id === updatedItem.id)

              if (updatedItemIndex !== index) {
                draft.data = draft.data.filter((item) => item.id !== updatedItem.id)
                index && draft.data.splice(index, 0, updatedItem)
              } else {
                draft.data[updatedItemIndex] = updatedItem
              }
            }),
          )
        } catch {}
      },
    }),
    deleteDefect: build.mutation<void, IDeleteDefectRequest>({
      query: ({ defectId }) => ({
        url: `/defect/${defectId}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ defectId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            defectsApi.util.updateQueryData('getDefects', {}, (draft) => {
              draft.data = draft.data.filter((item) => item.id !== defectId)
            }),
          )
        } catch {}
      },
    }),
    clearDefects: build.mutation<void, void>({
      query: () => ({
        url: '/defect/delete/all',
        method: 'DELETE',
      }),
      invalidatesTags: ['References', { type: 'References', id: 'DEFECT' }],
    }),
    getDropdownAssignmentTypes: build.query<IGetDropdownAssignmentTypesResponse, void>({
      query: () => ({
        url: `/defect/create/assignment-type`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References', { type: 'References', id: 'ASSIGNMENT_TYPE' }],
    }),
    exportDefects: build.query<IExportDefectsResponse, void>({
      query: () => ({
        url: `/defect/export`,
        method: 'POST',
        body: { timestamp: getTimestamp() },
      }),
    }),
    getDefectsExportResult: build.query<IGetExportDefectsExportResultResponse, IGetExportDefectsExportResultRequest>({
      query: ({ ...params }) => ({
        url: `/defect/export/result`,
        method: 'GET',
        params,
      }),
    }),

    // Project mode
    getDefectsByProject: build.query<IGetDefectsByProjectResponse, IGetDefectsByProjectRequest>({
      query: ({ projectId, ...params }) => {
        return {
          method: 'GET',
          url: `/defect/${projectId}/list`,
          params: params,
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query

        const otherArgsChanged = queryChanged

        if (currentArg && otherArgsChanged) currentArg.page = 1

        return pageChanged || otherArgsChanged
      },
      providesTags: ['References', { type: 'References', id: 'DEFECT' }],
    }),
    createDefectByProject: build.mutation<ICreateDefectByProjectResponse, ICreateDefectByProjectRequest>({
      query: ({ projectId, body, ...params }) => ({
        url: `/defect/${projectId}/create`,
        method: 'POST',
        params,
        body,
      }),
      async onQueryStarted({ projectId, isModified, body, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { data: newItem, index } = data || {}

          if (isModified) {
            dispatch(
              defectsApi.util.updateQueryData('getDefectsByProject', { projectId }, (draft) => {
                if (typeof index !== 'number') return

                if (index >= 0 && index <= draft.data.length) {
                  draft.data.splice(index, 0, newItem)
                }
              }),
            )
          }
        } catch {}
      },
      invalidatesTags: ['ReferenceList'],
    }),
    editDefectByProject: build.mutation<IEditDefectByProjectResponse, IEditDefectByProjectRequest>({
      query: ({ projectId, defectId, body }) => ({
        url: `/defect/${projectId}/${defectId}/update`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ projectId, defectId, isModified, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const { data: updatedItem, index } = data || {}

          if (isModified) {
            dispatch(
              defectsApi.util.updateQueryData('getDefectsByProject', { projectId }, (draft) => {
                const updatedItemIndex = draft.data.findIndex((item) => item.id === updatedItem.id)

                if (updatedItemIndex !== index) {
                  draft.data = draft.data.filter((item) => item.id !== updatedItem.id)
                  index && draft.data.splice(index, 0, updatedItem)
                } else {
                  draft.data[updatedItemIndex] = updatedItem
                }
              }),
            )
          }
        } catch {}
      },
      invalidatesTags: ['ReferenceList'],
    }),
    deleteDefectByProject: build.mutation<void, IDeleteDefectByProjectRequest>({
      query: ({ projectId, defectId }) => ({
        url: `/defect/${projectId}/${defectId}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ projectId, defectId, isModified, ...patch }, { dispatch, queryFulfilled }) {
        try {
          if (isModified) {
            dispatch(
              defectsApi.util.updateQueryData('getDefectsByProject', { projectId }, (draft) => {
                draft.data = draft.data.filter((item) => item.id !== defectId)
              }),
            )
          }
        } catch {}
      },
      invalidatesTags: ['ReferenceList'],
    }),
    clearDefectsByProject: build.mutation<void, IClearDefectsByProjectRequest>({
      query: ({ projectId }) => ({
        url: `/defect/${projectId}/delete/all`,
        method: 'DELETE',
      }),
      invalidatesTags: ['References', { type: 'References', id: 'DEFECT' }],
    }),
    getDropdownAssignmentTypesByProject: build.query<
      IGetDropdownAssignmentTypesByProjectResponse,
      IGetDropdownAssignmentTypesByProjectRequest
    >({
      query: ({ projectId }) => ({
        url: `/defect/${projectId}/create/assignment-type`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References', { type: 'References', id: 'ASSIGNMENT_TYPE' }],
    }),
    exportDefectsByProject: build.query<IExportDefectsByProjectResponse, IExportDefectsByProjectRequest>({
      query: ({ projectId }) => ({
        url: `/defect/${projectId}/export`,
        method: 'POST',
        body: { timestamp: getTimestamp() },
      }),
    }),
    getDefectsExportResultByProject: build.query<
      IGetDefectsExportResultByProjectResponse,
      IGetDefectsExportResultByProjectRequest
    >({
      query: ({ projectId, ...params }) => ({
        url: `/defect/${projectId}/export/result`,
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  // Admin mode
  useGetDefectsQuery,
  useCreateDefectMutation,
  useClearDefectsMutation,
  useDeleteDefectMutation,
  useEditDefectMutation,
  useGetDropdownAssignmentTypesQuery,
  useLazyExportDefectsQuery,
  useLazyGetDefectsExportResultQuery,

  // Project mode
  useGetDefectsByProjectQuery,
  useCreateDefectByProjectMutation,
  useClearDefectsByProjectMutation,
  useDeleteDefectByProjectMutation,
  useEditDefectByProjectMutation,
  useGetDropdownAssignmentTypesByProjectQuery,
  useLazyExportDefectsByProjectQuery,
  useLazyGetDefectsExportResultByProjectQuery,
} = defectsApi
