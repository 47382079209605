import { AuditDrawer } from '../../../Remarks/components/AuditDrawer'
import { AuditCell } from '../../../Remarks/components/RemarksTable/cells/AuditCell'
import { EmptyPageWrapper, KSG_TABLE_CELL_HEIGHT, TableWrapper } from './WorkTable.styles'
import { WorkTableProps } from './WorkTable.types'
import { Stack, Typography } from '@mui/material'
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { ksgApi, useEditWorkMutation, useGetKsgWorksQuery } from 'api/ksg'
import { CreateWork } from 'api/ksg/api.types'
import {
  WorkFull,
  WorkFullAssignmentType,
  WorkFullContractor,
  WorkFullObject,
  WorkFullQcInfo,
  WorkFullRd,
} from 'api/ksg/types'
import { EmptyPage, EmptyPageData } from 'components/EmptyPage'
import { Progress } from 'components/Progress'
import { WorkStatuses, workStatuses, workStatusesBack, workStatusesClient, workStatusesColor } from 'core/types/ksg'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { useSearch } from 'hooks/useSearch'
import { TableCell, TableCellWrapper } from 'pages/Documents/components/DocumentsTable/DocumentsTable.styles'
import { getEmptyPageData } from 'pages/Projects'
import {
  CustomColumnDef,
  RemarksTableBody,
  RemarksTableCell,
  RemarksTableContainer,
  RemarksTableHead,
  RemarksTableRow,
} from 'pages/Remarks/components/RemarksTable'
import { StatusCell, StatusCellMenuItemProps } from 'pages/Remarks/components/RemarksTable/cells/StatusCell'
import { FC, MouseEvent, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ksgShouldResetPageSelector } from 'store/slices/infiniteScroll'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'
import { theme } from 'styles/theme'
import { NUMBER_OF_TABLE_ITEMS_TO_FETCH } from 'utils/constants'
import { parseResponseDate } from 'utils/dates/parseResponseDate'

export const WorkTable: FC<WorkTableProps> = ({ onEditDrawerOpen }) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { searchValue } = useSearch({ preventClearOnUnmount: true })
  const [selectedId, setSelectedId] = useState<number | null>(null)

  const onRowClick = (work: WorkFull) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onEditDrawerOpen && onEditDrawerOpen(work)
    setSelectedId(work.id)
  }

  // Audit
  const [openedAuditId, setOpenedAuditId] = useState<number | null>(null)
  const [openedAuditWorkNumber, setOpenedAuditWorkNumber] = useState<number | null>(null)

  const onAuditOpen = (id: number, index: number = -1) => {
    setOpenedAuditId(id)

    if (index > -1) {
      setOpenedAuditWorkNumber(Number(queryData.data?.total) - index)
    }
  }

  const onAuditClose = () => {
    setOpenedAuditId(null)
    setOpenedAuditWorkNumber(null)
  }

  // Status cell
  const [editWork] = useEditWorkMutation()

  const onStatusSelect = (projectId: number, workId: number, body: CreateWork) => {
    editWork({ projectId, workId, body })
  }

  const columnHelper = createColumnHelper<WorkFull>()

  const columns: CustomColumnDef<WorkFull>[] = [
    {
      accessorKey: '№',
      header: '№ работы',
      minSize: 89,
      size: 89,
      cell: (info) => Number(queryData.data?.total) - info.row.index,
    },
    {
      accessorKey: 'name',
      header: 'Наименование работы',
      minSize: 292,
      size: 292,
      textAlign: 'left',
    },
    {
      accessorKey: 'contractor',
      header: 'Подрядчик',
      minSize: 180,
      size: 180,
      cell: (info) => {
        const { shortName } = info.getValue<WorkFullContractor>() || {}
        return shortName || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'capacity.unit',
      header: 'Ед. измерения',
      minSize: 121,
      size: 121,
      cell: (info) => {
        const unit = info.getValue<string>()
        return unit || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'capacity.total',
      header: 'Общий объем',
      minSize: 138,
      size: 138,
      cell: (info) => {
        const total = info.getValue<string | null>()
        return total ? total.replace('.', ',') : 0
      },
    },
    {
      accessorKey: 'capacity.confirmed',
      header: 'Подтв. объем',
      minSize: 138,
      size: 138,
      cell: (info) => {
        const confirmed = info.getValue<string | null>()
        return confirmed ? confirmed.replace('.', ',') : 0
      },
    },
    {
      accessorKey: 'qcInfo',
      header: 'Статус приемки',
      minSize: 204,
      size: 204,
      maxSize: 204,
      cell: (info) => {
        const { status, date } = info.getValue<WorkFullQcInfo>() || {}
        // @ts-ignore
        const statusCellMenuItemsData: StatusCellMenuItemProps<WorkStatuses>[] | false = workStatuses.map(
          (menuStatus) => {
            if ((workStatusesBack[menuStatus] === 'ACCEPT' || workStatusesBack[menuStatus] === 'PARTIAL') && !date) {
              return false
            }

            const valueForOnClick = workStatusesBack[menuStatus]
            const checked = status === valueForOnClick

            return {
              value: menuStatus,
              valueForOnClick,
              checked: checked,
              key: menuStatus,
              children: menuStatus,
            }
          },
        )

        return (
          <StatusCell
            variant='works'
            info={info}
            onSelect={onStatusSelect}
            itemsData={statusCellMenuItemsData}
            statusData={{
              body: workStatusesClient[status || 'EMPTY'],
              color: workStatusesColor[status || 'EMPTY'],
            }}
          />
        )
      },
      isCustomCell: true,
    },
    {
      ...columnHelper.display({
        id: 'actions',
        minSize: 50,
        size: 50,
        maxSize: 50,
        cell: (info) => <AuditCell info={info} onAuditOpen={onAuditOpen} />,
      }),
      isCustomCell: true,
    },
    {
      accessorKey: 'qcInfo.date',
      header: 'Дата приемки',
      minSize: 193,
      size: 193,
      maxSize: 193,
      cell: (info) => {
        const date = info.getValue<string>()
        return (date && parseResponseDate(date).fullDate) || '—'
      },
    },
    {
      accessorKey: 'qcInfo.comment',
      header: 'Комментарий',
      minSize: 290,
      size: 290,
      maxSize: 290,
      cell: (info) => {
        const comment = info.getValue<string>()
        return comment || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'identifier',
      header: 'ID работы',
      minSize: 107,
      size: 107,
      maxSize: 107,
      cell: (info) => {
        const identifier = info.getValue<string | null>()
        return identifier || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'assignmentType',
      header: 'Вид работ',
      minSize: 180,
      size: 180,
      maxSize: 180,
      cell: (info) => {
        const { title } = info.getValue<WorkFullAssignmentType>() || {}
        return title || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'object',
      header: 'Объект',
      minSize: 137,
      size: 137,
      maxSize: 137,
      cell: (info) => {
        const { title } = info.getValue<WorkFullObject>() || {}
        return title || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'rd',
      header: 'Раздел/Марка',
      minSize: 150,
      size: 150,
      maxSize: 150,
      cell: (info) => {
        const { fullName } = info.getValue<WorkFullRd>() || {}
        return fullName || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'documentCypher',
      header: 'Шифр документа',
      minSize: 180,
      size: 180,
      maxSize: 180,
      cell: (info) => {
        const documentCypher = info.getValue<string | null>()
        return documentCypher || '—'
      },
      textAlign: 'left',
    },
    {
      accessorKey: 'period.start',
      header: 'Дата начала',
      minSize: 131,
      size: 131,
      maxSize: 131,
      cell: (info) => {
        const start = info.getValue<string>()
        return (start && parseResponseDate(start).fullDate) || '—'
      },
    },
    {
      accessorKey: 'period.end',
      header: 'Дата окончания',
      minSize: 131,
      size: 131,
      maxSize: 131,
      cell: (info) => {
        const end = info.getValue<string>()
        return (end && parseResponseDate(end).fullDate) || '—'
      },
    },
  ]

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { queryData, onScrollWithInfiniteLoad } = useInfiniteScroll({
    refElement: tableContainerRef,
    api: ksgApi,
    tagName: 'Works',
    limit: NUMBER_OF_TABLE_ITEMS_TO_FETCH,
    query: useGetKsgWorksQuery,
    shouldResetPageSelector: ksgShouldResetPageSelector,
    arg: {
      projectId,
      query: searchValue || undefined,
    },
    table: 'ksg',
  })

  const { data = [], total } = queryData.data || {}
  const { isLoading } = queryData || {}

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  })

  const { rows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => KSG_TABLE_CELL_HEIGHT,
    overscan: 5,
    getScrollElement: () => tableContainerRef?.current!,
  })

  const emptyPageData: EmptyPageData = getEmptyPageData(<>Данные отсутствуют.</>)

  const onHoverRow = (rowId: number) => {
    if (selectedId === rowId) setSelectedId(null)
  }

  return (
    <>
      {isLoading && <Progress />}

      {!isLoading && !!data.length && (
        <TableWrapper>
          <RemarksTableContainer onScroll={onScrollWithInfiniteLoad} ref={tableContainerRef}>
            <RemarksTableHead>
              <RemarksTableRow>
                <RemarksTableCell
                  key={'info'}
                  style={{
                    flex: 1,
                    minWidth: 820,
                    width: 820,
                    minHeight: '35px',
                    height: '35px',
                  }}
                >
                  <Typography variant='body2'>Общая информация</Typography>
                </RemarksTableCell>
                <RemarksTableCell
                  key={'control'}
                  style={{
                    flex: 1,
                    minWidth: 875,
                    width: 875,
                    minHeight: '35px',
                    height: '35px',
                  }}
                >
                  <Typography variant='body2'>Строительный контроль</Typography>
                </RemarksTableCell>
                <RemarksTableCell
                  key={'data'}
                  style={{
                    flex: 1,
                    minWidth: 1016,
                    width: 1016,
                    minHeight: '35px',
                    height: '35px',
                  }}
                >
                  <Typography variant='body2'>Сведения о работе</Typography>
                </RemarksTableCell>
              </RemarksTableRow>
              {table.getHeaderGroups().map((headerGroup) => (
                <RemarksTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <RemarksTableCell
                      key={header.id}
                      style={{
                        flex: 1,
                        minWidth: header.column.columnDef.minSize,
                        width: header.column.columnDef.size,
                        maxWidth: header.column.columnDef.maxSize,
                        minHeight: '35px',
                        height: '35px',
                      }}
                    >
                      <Typography variant='body2'>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </RemarksTableCell>
                  ))}
                </RemarksTableRow>
              ))}
            </RemarksTableHead>

            <RemarksTableBody style={{ height: virtualizer.getTotalSize() }}>
              {virtualizer.getVirtualItems().map((row) => {
                const work = data[row.index]

                return (
                  <RemarksTableRow
                    onClick={onRowClick(work)}
                    key={row.key}
                    data-index={row.index}
                    onMouseEnter={() => onHoverRow(work.id)}
                    ref={virtualizer.measureElement}
                    showAnimation={work.id === selectedId}
                    style={{
                      width: '100%',
                      transform: `translateY(${row.start - virtualizer.options.scrollMargin}px)`,
                    }}
                  >
                    {columns.map((column, columnIndex) => {
                      const cell = rows[row.index].getVisibleCells()[columnIndex]
                      const { minSize, size, maxSize, textAlign } = column

                      if (column.isCustomCell) {
                        return flexRender(cell.column.columnDef.cell, cell.getContext())
                      }

                      return (
                        <TableCellWrapper
                          key={column.id}
                          style={{
                            flex: 1,
                            minWidth: minSize,
                            width: size,
                            maxWidth: maxSize,
                          }}
                        >
                          <TableCell className='cell'>
                            <Typography variant='body2' width='100%' textAlign={textAlign}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Typography>
                          </TableCell>
                        </TableCellWrapper>
                      )
                    })}
                  </RemarksTableRow>
                )
              })}
            </RemarksTableBody>
          </RemarksTableContainer>

          <AuditDrawer
            variant='ksg'
            openedAuditId={openedAuditId}
            openedAuditWorkNumber={openedAuditWorkNumber}
            onClose={onAuditClose}
            // key={projectId + 'ksg' + (openedAuditId ?? '')}
          />
        </TableWrapper>
      )}

      {!isLoading && !data.length && <EmptyPage data={{ text: 'Данные отсутствуют.', buttons: [] }} fullPage />}
    </>
  )
}
