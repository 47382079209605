import { StyledMenu } from './Menu.styles'
import { IMenuData, IMenuProps } from './Menu.types'
import { Close as CloseIcon } from '@mui/icons-material'
import { MenuItem, Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { Progress } from 'components/Progress'
import { Tooltip } from 'components/Tooltip'
import { FC, useEffect, useMemo, useState } from 'react'
import { theme } from 'styles/theme'

export const Menu: FC<IMenuProps> = ({ anchorEl, title, onClose, menuData }) => {
  const [width, setWidth] = useState(0)
  const isOpen = !!anchorEl

  useEffect(() => {
    if (isOpen) setWidth(anchorEl.clientWidth)
  }, [isOpen])

  const onMenuItemClick = (item: IMenuData) => {
    if (item?.loading) return

    item.action()
    !item.notCloseAfterClick && onClose()
  }

  const filtredMenuData = useMemo(() => {
    return menuData.filter((item) => !item.hidden)
  }, [menuData])

  return (
    <StyledMenu anchorEl={anchorEl} open={isOpen} onClose={onClose}>
      <Stack spacing={0.5} width={`${width - 10}px`}>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography
            color={theme.palette.secondary.gray}
            variant='body2'
            display={'flex'}
            flex={1}
            justifyContent={'center'}
            paddingLeft={'40px'}
          >
            {title}
          </Typography>
          <Button icon variant='text' onClick={onClose}>
            <CloseIcon color='primary' />
          </Button>
        </Stack>
        <Stack spacing={1}>
          {filtredMenuData.map((item) => (
            <Tooltip
              placement='left'
              title={item.tooltipTitle}
              bgColor={theme.palette.bg.white}
              textColor={theme.palette.text.dark}
            >
              <MenuItem
                onClick={() => onMenuItemClick(item)}
                sx={{
                  borderRadius: '100px',
                  border: `1px solid ${theme.palette.primary.main}`,
                  justifyContent: 'center',
                }}
              >
                {item?.loading ? (
                  <Progress size={'24px'} />
                ) : (
                  <Stack direction={'row'} spacing={1}>
                    {item.icon}
                    <Typography color={item.labelColor || theme.palette.primary.main} variant='body1'>
                      {item.label}
                    </Typography>
                  </Stack>
                )}
              </MenuItem>
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    </StyledMenu>
  )
}
