import { ExcelUploadResponse } from 'core/types/global'
import { UploadDrawerTopBar, UploadDrawerWrapper } from './UploadDrawer.styles'
import { UploadDrawerProps } from './UploadDrawer.types'
import { UploadDrawerForm } from './UploadDrawerForm'
import { UploadResultsDrawer } from './UploadResultsDrawer'
import { Drawer, Typography } from '@mui/material'
import { Divider } from 'components/Divider/'
import React, { useCallback, useEffect, useState } from 'react'
import { theme } from 'styles/theme'
import { useConfirmDialog, UseExitConfirmProps } from 'hooks/useConfirmDialog'
import { useMutationHandlers } from 'hooks/useMutationHandlers'
import { useParams } from 'react-router-dom'

export const UploadDrawer: React.FC<UploadDrawerProps> = ({
  open,
  mode,
  onSubmit,
  importResponse,
  onClose,
  title,
  text,
  attentionText,
  uploadData,
  getExampleLinkQuery,
  getExampleLinkQueryParams,
  ContentBeforeFileUpload,
  getImportResultQuery,
  getImportResultQueryParams,
  showUpdatedItems = true,
  addedItemsText,
}) => {
  const [files, setFiles] = useState<File[] | null>(null)
  const [responseData, setResponseData] = useState<ExcelUploadResponse | undefined>(undefined)
  const [dirty, setDirty] = useState<boolean>(false)
  const [isResultsDrawerOpen, setIsResultsDrawerOpen] = useState<boolean>(false)
  const [getImportResult, getImportResultQueryResponse] = getImportResultQuery?.() || [{}, {}]
  const { projectId: projectIdString } = useParams()

  useEffect(() => {
    if (files?.length && files[0] instanceof File) {
      onSubmit(files[0])
    }
  }, [files])

  const onResultsDrawerOpen = useCallback(() => {
    setIsResultsDrawerOpen(true)
  }, [])

  const onResultsDrawerClose = useCallback(() => {
    setIsResultsDrawerOpen(false)
  }, [])

  const onDrawerClose = (dirty: boolean, immediately?: boolean) => {
    !dirty ? onClose() : openConfirm()
    setResponseData(undefined)
    setFiles(null)
  }

  const onFormChange = (dirty: boolean) => {
    setResponseData(undefined)
    setDirty(dirty)
  }

  const onFormSubmit = (value: File[]) => {
    setFiles(value)
    setDirty(false)
  }

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClose()
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  }
  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  useMutationHandlers(importResponse, (data) => {
    getImportResultQuery ? getImportResult({ ...getImportResultQueryParams, id: data.data }) : setResponseData(data)
  })

  useMutationHandlers(getImportResultQueryResponse, (data) => {
    setResponseData(data)
  })

  return (
    <Drawer anchor='right' open={open} onClose={() => onDrawerClose(dirty)}>
      <UploadDrawerWrapper>
        <UploadDrawerTopBar>
          <Typography variant='h1' color={theme.palette.primary.main}>
            {title}
          </Typography>
        </UploadDrawerTopBar>

        <Divider />

        <UploadDrawerForm
          mode={mode}
          onSubmit={onFormSubmit}
          onChange={onFormChange}
          onClose={() => onDrawerClose(dirty)}
          onResultsDrawerOpen={onResultsDrawerOpen}
          text={text}
          attentionText={attentionText}
          uploadData={uploadData}
          responseData={responseData}
          loading={false}
          getExampleLinkQuery={getExampleLinkQuery}
          getExampleLinkQueryParams={getExampleLinkQueryParams}
          ContentBeforeFileUpload={ContentBeforeFileUpload}
          showUpdatedItems={showUpdatedItems}
          addedItemsText={addedItemsText}
        />
      </UploadDrawerWrapper>
      <UploadResultsDrawer
        open={isResultsDrawerOpen}
        onClose={onResultsDrawerClose}
        mode={mode}
        responseData={responseData}
      />

      <ConfirmDialog />
    </Drawer>
  )
}
