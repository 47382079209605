import { TableManagmentProps } from './TableManagment.types'
import { Add as AddIcon, ArrowBack as ArrowBackIcon, MoreVert as MenuIcon } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { Button } from 'components/Button'
import { Menu } from 'components/Menu'
import { IMenuData } from 'components/Menu/Menu.types'
import { Tooltip } from 'components/Tooltip'
import { UseExitConfirmProps, useConfirmDialog } from 'hooks/useConfirmDialog'
import { FC, useCallback, useState, MouseEvent } from 'react'
import { profileSelector } from 'store/slices/profile'
import { useTypedSelector } from 'store/store'

export const TableManagment: FC<TableManagmentProps> = ({
  onReturnClick,
  onClearClick,
  onImportClick,
  onAddClick,
  onExportClick,
  show = true,
  buttonManagmentTitle,
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const { role } = useTypedSelector(profileSelector)

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      onClearClick && onClearClick()
    }
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
    title: 'Очистить справочник?',
    body: 'Все позиции справочника будут безвозвратно удалены.',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseMenu = () => {
    setAnchorEl(null)
  }

  const menuData: IMenuData[] = [
    {
      label: 'Импорт',
      action: () => onImportClick && onImportClick(),
      hidden: !onImportClick,
    },
    {
      label: 'Экспорт',
      action: () => onExportClick && onExportClick(),
      hidden: !onExportClick,
    },
    {
      label: 'Очистить',
      action: openConfirm,
      hidden: role !== 'admin' || !onClearClick,
    },
  ]

  return (
    <Box margin='8px 0' display='flex' justifyContent='space-between'>
      <Button onClick={onReturnClick} startIcon={<ArrowBackIcon color='secondary' />} variant='text'>
        <Typography variant='buttonMedium'>Назад</Typography>
      </Button>

      {show && (
        <Stack direction='row' justifyContent='flex-end' spacing={1.5} sx={{ width: '100%' }}>
          {onAddClick && (
            <Tooltip title={'Добавить'}>
              <div>
                <Button icon={true} onClick={onAddClick} color='secondary' variant='outlined'>
                  <AddIcon />
                </Button>
              </div>
            </Tooltip>
          )}
          {buttonManagmentTitle && (
            <>
              <Button
                color='secondary'
                variant='outlined'
                endIcon={<MenuIcon />}
                style={{
                  maxWidth: '100%',
                  padding: '8px 8px 6px 16px',
                  justifyContent: 'space-between',
                  minWidth: '180px',
                }}
                onClick={onOpenMenu}
              >
                {buttonManagmentTitle}
              </Button>

              <Menu anchorEl={anchorEl} title={buttonManagmentTitle} onClose={onCloseMenu} menuData={menuData} />
            </>
          )}
        </Stack>
      )}
      <ConfirmDialog />
    </Box>
  )
}
