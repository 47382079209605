import { api } from '../api'
import {
  ClearReferenceRequest,
  CreateSectionRequest,
  CreateSectionResponse,
  DeleteSectionRequest,
  DeleteSectionResponse,
  EditSectionRequest,
  EditSectionResponse,
  GetProjectContractorsRequest,
  GetProjectReferencesRequest,
  GetProjectReferencesResponse,
  GetReferenceDataRequest,
  GetReferenceDataResponse,
  GetReferenceImportResultRequest,
  GetReferenceLinkExampleRequest,
  GetReferenceLinkExampleResponse,
  IExportReferenceByProjectRequest,
  IExportReferenceByProjectResponse,
  IExportReferenceRequest,
  IExportReferenceResponse,
  IGetReferenceExportResultByProjectRequest,
  IGetReferenceExportResultByProjectResponse,
  IGetReferenceExportResultRequest,
  IGetReferenceExportResultResponse,
  UploadReferenceRequest,
  UploadReferenceResponse,
} from './api.types'
import { ExcelUploadResponse } from 'core/types/global'
import { getTimestamp } from 'utils/dates/getTimestamp'

export const referencesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectReferences: build.query<GetProjectReferencesResponse, GetProjectReferencesRequest>({
      query: ({ projectId }) => ({
        url: `/dictionary/${projectId}/list`,
        method: 'GET',
      }),
      providesTags: ['References', 'ReferencesUpload', 'ReferenceList'],
    }),
    getReferenceData: build.query<GetReferenceDataResponse, GetReferenceDataRequest>({
      query: ({ url, page, num, query }) => ({
        url: url,
        params: { num: num, page: page, query: query || '' },
        method: 'GET',
      }),
      providesTags: ['References', 'ReferencesUpload'],
    }),
    createSection: build.mutation<CreateSectionResponse, CreateSectionRequest>({
      query: ({ url, body }) => ({
        url: url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['References'],
    }),
    editSection: build.mutation<EditSectionResponse, EditSectionRequest>({
      query: ({ url, body }) => ({
        url: url,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(referencesApi.util.invalidateTags(['References']))
        } catch {}
      },
    }),
    deleteSection: build.mutation<DeleteSectionResponse, DeleteSectionRequest>({
      query: ({ url }) => ({
        url: url,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(referencesApi.util.invalidateTags(['References']))
        } catch {}
      },
    }),
    importReference: build.mutation<UploadReferenceResponse, UploadReferenceRequest>({
      query: ({ url, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: url,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    getReferenceImportResult: build.query<ExcelUploadResponse, GetReferenceImportResultRequest>({
      query: ({ url, id }) => ({
        url: url,
        params: { id },
        method: 'GET',
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(referencesApi.util.invalidateTags(['ReferencesUpload', 'References']))
        } catch {}
      },
    }),
    getReferenceLinkExample: build.query<GetReferenceLinkExampleResponse, GetReferenceLinkExampleRequest>({
      query: ({ type }) => ({
        url: '/dictionary/example/link',
        params: { type: type },
        method: 'GET',
      }),
    }),
    clearReference: build.mutation<void, ClearReferenceRequest>({
      query: ({ url }) => ({
        url: url,
        method: 'DELETE',
      }),
      invalidatesTags: ['References'],
    }),
    getDropdownContractorsReference: build.query<GetReferenceDataResponse, void>({
      query: () => ({
        url: '/project-member-representative/create/project-members',
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    getProjectContractors: build.query<GetReferenceDataResponse, GetProjectContractorsRequest>({
      query: ({ projectId }) => ({
        url: `/project-member-representative/${projectId}/create/project-members`,
        params: { page: 1, num: 99999 },
        method: 'GET',
      }),
      providesTags: ['References'],
    }),
    exportReference: build.query<IExportReferenceResponse, IExportReferenceRequest>({
      query: ({ url }) => ({
        url,
        method: 'POST',
        body: { timestamp: getTimestamp() },
      }),
    }),
    getReferenceExportResult: build.query<IGetReferenceExportResultResponse, IGetReferenceExportResultRequest>({
      query: ({ url, ...params }) => ({
        url,
        method: 'GET',
        params,
      }),
    }),
    exportReferenceByProject: build.query<IExportReferenceByProjectResponse, IExportReferenceByProjectRequest>({
      query: ({ url, projectId }) => ({
        url,
        method: 'POST',
        body: { timestamp: getTimestamp() },
      }),
    }),
    getReferenceExportResultByProject: build.query<
      IGetReferenceExportResultByProjectResponse,
      IGetReferenceExportResultByProjectRequest
    >({
      query: ({ url, projectId, ...params }) => ({
        url,
        method: 'GET',
        params,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetProjectReferencesQuery,
  useGetReferenceDataQuery,
  useCreateSectionMutation,
  useEditSectionMutation,
  useDeleteSectionMutation,
  useImportReferenceMutation,
  useLazyGetReferenceImportResultQuery,
  useLazyGetReferenceLinkExampleQuery,
  useClearReferenceMutation,
  useGetDropdownContractorsReferenceQuery,
  useGetProjectContractorsQuery,
  useLazyExportReferenceQuery,
  useLazyGetReferenceExportResultQuery,
  useLazyExportReferenceByProjectQuery,
  useLazyGetReferenceExportResultByProjectQuery,
} = referencesApi
