import { useEffect } from 'react'

interface InitialOptions {
  isSuccess?: boolean
  isFetching?: boolean
  data?: any
  isError?: boolean
  error?: any
}

export const useMutationHandlers = <TOptions extends InitialOptions>(
  options: TOptions,
  onSuccess?: (data: NonNullable<TOptions['data']>) => void,
  onFailure?: (error: NonNullable<TOptions['error']>) => void,
) => {
  const { isSuccess, data, isError, error, isFetching } = options

  useEffect(() => {
    if (isSuccess && !isFetching) {
      onSuccess?.(data)
      // if ('success' in (data || {}) && data.success === false) {
      //   onFailure?.(data)
      // } else {
      //   onSuccess?.(data)
      // }
    }
  }, [isFetching, isSuccess, data])

  useEffect(() => {
    if (isError) {
      onFailure?.(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error])
}
