import {
  CreateWorkRequest,
  CreateWorkResponse,
  DeleteWorkRequest,
  EditKsgStatusRequest,
  EditKsgStatusResponse,
  EditWorkRequest,
  GetDropdownParams,
  GetDropdownResponse,
  GetKsgAuditRequest,
  GetKsgAuditResponse,
  GetKsgImportResultRequest,
  GetKsgLinkExampleRequest,
  GetKsgLinkExampleResponse,
  GetKsgWorkResponse,
  GetKsgWorksRequest,
  UploadKsgRequest,
  UploadKsgResponse,
} from './api.types'
import { api } from 'api/api'
import { ExcelUploadResponse } from 'core/types/global'
import { setShouldResetPage } from 'store/slices/infiniteScroll'

export const ksgApi = api.injectEndpoints({
  endpoints: (build) => ({
    getKsgWorks: build.query<GetKsgWorkResponse, GetKsgWorksRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/work/${projectId}/list`,
        method: 'GET',
        params,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
        }
      },
      merge: (currentCache, newData, { arg }) => {
        currentCache.total = newData.total

        if (arg?.page === 1) {
          currentCache.data = [...newData.data]
          return
        }

        currentCache.data.push(...newData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const queryChanged = currentArg?.query !== previousArg?.query

        const otherArgsChanged = queryChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: [{ type: 'Works' }],
    }),
    createWork: build.mutation<CreateWorkResponse, CreateWorkRequest>({
      query: ({ projectId, body }) => ({
        url: `/work/${projectId}/create`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          // set should reset page
          dispatch(setShouldResetPage({ table: 'ksg', shouldResetPage: true }))
        } catch {}
      },
    }),
    getKsgLinkExample: build.query<GetKsgLinkExampleResponse, GetKsgLinkExampleRequest>({
      query: ({ projectId }) => ({
        url: `/work/${projectId}/import/example/link`,
        method: 'GET',
      }),
    }),
    importKsg: build.mutation<UploadKsgResponse, UploadKsgRequest>({
      query: ({ projectId, file }) => {
        if (file instanceof File) {
          const formData = new FormData()
          formData.append('file', file)

          return {
            url: `/work/${projectId}/import`,
            method: 'POST',
            body: formData,
          }
        }
      },
    }),
    getKsgImportResult: build.query<ExcelUploadResponse, GetKsgImportResultRequest>({
      query: ({ projectId, id }) => ({
        url: `/work/${projectId}/import/result`,
        params: { id },
        method: 'GET',
      }),
      async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          // set should reset page
          dispatch(setShouldResetPage({ table: 'ksg', shouldResetPage: true }))
        } catch {}
      },
    }),
    editWork: build.mutation<CreateWorkResponse, EditWorkRequest>({
      query: ({ projectId, workId, body }) => ({
        url: `/work/${projectId}/${workId}/update`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ projectId, workId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedWorkData } = await queryFulfilled
          const updatedWork = updatedWorkData.data

          dispatch(
            ksgApi.util.updateQueryData('getKsgWorks', { projectId }, (draft) => {
              const updatedWorkIndex = draft.data.findIndex((work) => work.id === updatedWork.id)
              draft.data[updatedWorkIndex] = updatedWork
            }),
          )

          dispatch(ksgApi.util.invalidateTags([{ type: 'Works', id: 'WORKS-AUDIT' }]))
        } catch {}
      },
    }),
    deleteWork: build.mutation<CreateWorkResponse, DeleteWorkRequest>({
      query: ({ projectId, workId }) => ({
        url: `/work/${projectId}/${workId}//delete`,
        method: 'DELETE',
      }),
      async onQueryStarted({ projectId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(ksgApi.util.invalidateTags(['Works']))
        } catch {}
      },
    }),
    getDropdownKsgObjects: build.query<GetDropdownResponse, GetDropdownParams>({
      query: ({ projectId, query, ...params }) => ({
        url: `/work/${projectId}/create/dropdown/object`,
        method: 'GET',
        params: {
          num: 9999,
          page: 1,
          query,
        },
      }),
      providesTags: ['Objects'],
    }),
    getDropdownAssigment: build.query<GetDropdownResponse, GetDropdownParams>({
      query: ({ projectId, query, ...params }) => ({
        url: `/work/${projectId}/create/dropdown/assignment-type`,
        method: 'GET',
        params: {
          num: 9999,
          page: 1,
          query,
        },
      }),
      providesTags: ['References'],
    }),
    getDropdownContractor: build.query<GetDropdownResponse, GetDropdownParams>({
      query: ({ projectId, query, ...params }) => ({
        url: `/work/${projectId}/create/dropdown/contractor`,
        method: 'GET',
        params: {
          num: 9999,
          page: 1,
          query,
        },
      }),
      providesTags: ['References'],
    }),
    getDropdownRd: build.query<GetDropdownResponse, GetDropdownParams>({
      query: ({ projectId, query, ...params }) => ({
        url: `/work/${projectId}/create/dropdown/rd`,
        method: 'GET',
        params: {
          num: 9999,
          page: 1,
          query,
        },
      }),
      providesTags: ['References'],
    }),

    editKsgStatus: build.query<EditKsgStatusResponse, EditKsgStatusRequest>({
      query: ({ projectId, workId, status }) => ({
        url: `/work/${projectId}/${workId}/update/status`,
        method: 'get',
        params: { status },
      }),
      async onQueryStarted({ projectId, workId, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedKsgData } = await queryFulfilled
          const { data: updatedKsg } = updatedKsgData || {}

          // getPrescriptions
          dispatch(
            ksgApi.util.updateQueryData('getKsgWorks', { projectId }, (draft) => {
              const updatedKsgIndex = draft.data.findIndex((ksg) => ksg.id === updatedKsg.id)

              draft.data[updatedKsgIndex] = updatedKsg
            }),
          )

          // getKsgAudit
          dispatch(ksgApi.util.invalidateTags([{ type: 'Works', id: 'WORKS-AUDIT' }]))
        } catch {}
      },
      providesTags: ['Works'],
    }),
    getKsgAudit: build.query<GetKsgAuditResponse, GetKsgAuditRequest>({
      query: ({ projectId, workId, ...params }) => ({
        url: `audit/${projectId}/work/${workId}`,
        params,
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return {
          projectId: queryArgs.projectId,
          workId: queryArgs.workId,
        }
      },
      merge: (currentCacheData, responseData, args) => {
        if (args.arg.page === 1) {
          return responseData
        }

        currentCacheData.data.push(...responseData.data)
      },
      forceRefetch({ currentArg, previousArg }) {
        const pageChanged = currentArg?.page !== previousArg?.page
        const projectChanged = currentArg?.projectId !== previousArg?.projectId

        const otherArgsChanged = projectChanged
        if (currentArg && otherArgsChanged) {
          currentArg.page = 1
        }

        return pageChanged || otherArgsChanged
      },
      providesTags: ['Works', { type: 'Works', id: 'WORKS-AUDIT' }],
    }),
  }),
  overrideExisting: false,
})
export const {
  useGetDropdownAssigmentQuery,
  useGetDropdownContractorQuery,
  useGetDropdownKsgObjectsQuery,
  useGetDropdownRdQuery,
  useEditWorkMutation,
  useCreateWorkMutation,
  useGetKsgWorksQuery,
  useDeleteWorkMutation,
  useLazyGetKsgLinkExampleQuery,
  useImportKsgMutation,
  useLazyGetKsgImportResultQuery,
  useLazyEditKsgStatusQuery,
  useGetKsgAuditQuery,
} = ksgApi
