import { ImportDrawerProps } from './ImportDrawer.types'
import {
  useLazyGetReferenceImportResultQuery,
  useImportReferenceMutation,
  useLazyGetReferenceLinkExampleQuery,
} from 'api/references'
import { ReferenceBackendIds } from 'api/references/types'
import { UploadDrawer } from 'components/UploadDrawer'
import { Reference, basicReferences } from 'pages/Administration/References/References.types'
import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

export const ImportDrawer: FC<ImportDrawerProps> = ({ isOpen, onClose }) => {
  const { projectId, referenceId } = useParams()
  const [importReference, importReferenceResponse] = useImportReferenceMutation()

  const currentReference: Reference = useMemo(() => {
    return basicReferences.filter((item) => item.id.toLowerCase() === referenceId)[0]
  }, [referenceId])

  const onImportClick = (file: File) => {
    importReference({
      url: currentReference.api.upload[projectId ? 'projectUrl' : 'url'].replace('{project}', String(projectId)),
      file: file,
    })
  }

  return (
    <UploadDrawer
      open={isOpen}
      mode='single'
      onSubmit={onImportClick}
      importResponse={importReferenceResponse}
      onClose={onClose}
      title='Загрузка справочника'
      text='Вы можете добавить справочник с помощью exсel документа с уже подготовленными данными.'
      attentionText='В случае если у Вас будут совпадения названий существующих позиций с новыми из файла, новые значения заменят существующие.
    Это действие нельзя отменить.'
      uploadData={{
        title: 'Загрузить xls(х)',
        text: 'Чтобы загрузить документ, выберите его из папки на компьютере или просто перетяните его в эту область.',
        formats: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
      }}
      getExampleLinkQuery={useLazyGetReferenceLinkExampleQuery}
      getExampleLinkQueryParams={{
        type: ReferenceBackendIds[currentReference.id as keyof typeof ReferenceBackendIds],
      }}
      getImportResultQuery={useLazyGetReferenceImportResultQuery}
      getImportResultQueryParams={{
        url: currentReference.api.getUploadResult[projectId ? 'projectUrl' : 'url'].replace(
          '{project}',
          String(projectId),
        ),
      }}
    />
  )
}
