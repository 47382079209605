import { Button, Stack, Typography } from '@mui/material'
import styled from 'styled-components'
import { getProps } from 'utils/getProps'

/**
 * немного расширил интерфейс со стилями, чтобы подогнать компонент под свой экран
 */
interface DragWrapperProps {
  drag: boolean
  width?: string
  height?: string
  margin?: string
}

/**
 * использовал новый хелпер
 */
export const DragWrapper = styled(Stack)<DragWrapperProps>`
  width: ${getProps<DragWrapperProps>('width') || '100%'};
  height: ${getProps<DragWrapperProps>('height') || '300px'};
  margin: ${getProps<DragWrapperProps>('margin') || '0'};
  text-align: center;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 8px;
  outline: ${(props) => (props.drag ? `2px solid ${props.theme.palette.secondary.gray}` : '')};

  & > div {
    box-shadow: ${(props) => (props.drag ? 'inset 0px 0px 250px rgba(0, 26, 77, 0.08)' : '')};
  }
`
interface UploadWrapperProps {
  isPointer: boolean
}

export const UploadWrapper = styled(Stack)<UploadWrapperProps>`
  width: 100%;
  min-height: 300px;
  padding: 16px;
  text-align: center;
  background-color: ${(props) => props.theme.palette.bg.shades};
  border-radius: 8px;
  cursor: ${(props) => (props.isPointer ? 'pointer' : 'initial')};
`

export const UploadTitle = styled(Typography)`
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
`

export const UploadText = styled(Typography)`
  max-width: 285px;
  word-break: break-word;
`

export const ReLoadButton = styled(Stack)`
  padding: 2px 8px;
  background-color: ${(props) => props.theme.palette.bg.white};
  border-radius: 6px;
  cursor: pointer;

  & .MuiTypography-root {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.primary.main};
  }
`

export const ShowErrorButton = styled(Button)`
  width: fit-content;
  height: 26px;
  padding: 2px 8px;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.error.main};
  background-color: rgba(255, 177, 177, 0.15);
  border-radius: 6px;

  &:hover {
    background-color: rgba(255, 177, 177, 0.15);
  }
`

export const ShowResultsButton = styled(ShowErrorButton)`
  margin-top: 8px;
  padding: 7px 16px;
  color: ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.bg.white};

  &:hover {
    background-color: ${(props) => props.theme.palette.bg.white};
  }
`
