import { UseQuery, UseQueryOptions, UseQueryResult } from 'api/api.types'
import { TABLE_CELL_HEIGHT } from 'pages/Remarks/components/RemarksTable/RemarksTable.styles'
import React, { RefObject, useCallback, useEffect, useState } from 'react'
import { clearShouldResetPage, setShouldResetPage, TablesWithInfiniteScroll } from 'store/slices/infiniteScroll'
import { RootState, useAppDispatch, useTypedSelector } from 'store/store'
import { isEqual } from 'lodash'

export interface UseObserverProps<Res, Req> {
  refElement?: RefObject<any>
  api?: any
  tagName?: string
  limit: number
  query: UseQuery<Res, Req>
  arg: Req
  startPage?: number
  startId?: number
  options?: UseQueryOptions
  shouldResetPageSelector?: (state: RootState) => boolean
  scrollByIndex?: (index: number) => void
  threshold?: number
  table: TablesWithInfiniteScroll
}

export interface UseObserverData<Res> {
  fetchMoreOnTopReached: () => void
  fetchMoreOnBottomReached: () => void
  queryData: UseQueryResult<Res>
  hasNextPage: boolean
  onRefetch: (id: number) => void
  currentPage: number | null
}

export interface ScrollByIndexResponse {
  targetId: number
  rowIndex: number
}

export interface ObserverQueryData {
  targetId: number | null
  isReverse: boolean
  isCentered: boolean
}

const emptyObserverQueryData: ObserverQueryData = {
  targetId: null,
  isReverse: false,
  isCentered: false,
}

export const useObserver = <Res, Req>({
  refElement,
  api,
  tagName,
  limit,
  query,
  arg,
  startPage,
  startId,
  options,
  shouldResetPageSelector = () => false,
  table,
}: UseObserverProps<Res, Req>): UseObserverData<Res> => {
  const dispatch = useAppDispatch()
  const shouldResetPage = useTypedSelector(shouldResetPageSelector)
  const [isPageSaved, setIsPageSaved] = useState(false)

  const [observerQueryData, setObserverQueryData] = useState<ObserverQueryData>({
    targetId: startId || null,
    isReverse: false,
    isCentered: false,
  })
  const { targetId, isReverse, isCentered } = observerQueryData

  useEffect(() => {
    if (startId && startId === targetId && !isPageSaved) {
      // @ts-ignore
      const isDataExisted = Array.isArray(queryData.data?.data) && queryData.data?.data?.length
      if (isDataExisted) {
        setIsPageSaved(true)
        return
      }
    }

    refElement && refElement?.current?.scrollTo({ top: 0 })

    setObserverQueryData(emptyObserverQueryData)
  }, [...(Object.values(arg || {}) || [])])

  useEffect(() => {
    if (shouldResetPage) {
      refElement && refElement?.current?.scrollTo({ top: 0 })

      isEqual(observerQueryData, emptyObserverQueryData)
        ? dispatch(api.util.invalidateTags([{ type: tagName }]))
        : setObserverQueryData(emptyObserverQueryData)

      dispatch(setShouldResetPage({ table, shouldResetPage: false }))
    }
  }, [shouldResetPage])

  const returnScrollPosition = useCallback(
    (length: number) => {
      refElement && refElement?.current?.scrollTo({ top: refElement?.current?.scrollTop + length * TABLE_CELL_HEIGHT })
    },
    [refElement],
  )

  const scrollByIndex = (dataList: any[]): ScrollByIndexResponse => {
    // @ts-ignore
    const rowIndex = dataList.findIndex((item) => item.id === targetId)
    const scrollValue = (rowIndex * TABLE_CELL_HEIGHT || 0) - (refElement?.current?.offsetHeight || 0) / 2.5
    refElement?.current?.scrollTo({ top: scrollValue, behavior: 'auto' })

    return { targetId: targetId!, rowIndex }
  }

  const queryData = query(
    {
      ...arg,
      returnScrollPosition,
      scrollByIndex,
      num: limit,
      last: targetId,
      reverse: isReverse,
      centered: isCentered,
    },
    options,
  )

  // @ts-ignore
  const queryDataList = queryData.data?.data

  const fetchPrevPage = useCallback(() => {
    if (targetId) {
      setObserverQueryData({
        targetId: queryDataList[0].id,
        isReverse: true,
        isCentered: false,
      })
    }
  }, [queryDataList, targetId])

  const fetchNextPage = useCallback(() => {
    if (queryDataList.length) {
      setObserverQueryData({
        targetId: queryDataList.slice(-1)[0].id,
        isReverse: false,
        isCentered: false,
      })
    }
  }, [queryDataList])

  const hasNextPage = Array.isArray(queryDataList)
  // const hasNextPage = Array.isArray(queryData.data?.data) && queryData.data?.data?.length === page * limit
  const loading = queryData.isFetching

  const fetchMoreOnTopReached = React.useCallback(() => {
    if (!loading && hasNextPage) {
      fetchPrevPage()
    }
  }, [fetchPrevPage, loading, hasNextPage])

  const fetchMoreOnBottomReached = React.useCallback(() => {
    if (!loading && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, loading, hasNextPage])

  const onRefetch = useCallback(
    (id: number) => {
      // setTargetId(id)
      // setIsCentered(true)

      // setObserverQueryData(prev => ({ ...prev, targetId: id, isCentered: true }))
      setObserverQueryData({
        targetId: id,
        isReverse: false,
        isCentered: true,
      })
    },
    [queryData],
  )

  return {
    fetchMoreOnTopReached,
    fetchMoreOnBottomReached,
    queryData,
    hasNextPage,
    onRefetch,
    currentPage: targetId,
  }
}
