import { FormikConfig, useFormik } from 'formik'
import { FormikErrors, FormikValues } from 'formik/dist/types'
import { useEffect, useMemo } from 'react'

const mapErrors = <Values extends FormikValues = FormikValues>(initialErrors: any): FormikErrors<Values> => {
  if (!initialErrors?.inner) {
    return {}
  }
  return initialErrors?.inner?.reduce((result: Record<string, string>, error?: { path?: string; message?: string }) => {
    if (error?.path && error?.message) {
      result[error.path] = error.message
    }
    return result
  }, {}) as FormikErrors<Values>
}

export const useForm = <Values extends FormikValues = FormikValues>(options: FormikConfig<Values>) => {
  const { validationSchema } = options
  const formik = useFormik(options)

  const { errors, values, setTouched, setErrors } = formik

  useEffect(() => {
    try {
      validationSchema?.validateSync(values, { abortEarly: false })
    } catch (initialErrors) {
      setErrors(mapErrors(initialErrors))
      setTouched({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationSchema, setErrors, setTouched])

  useEffect(() => {
    console.log('form errors:', errors)
  }, [errors])

  const isSubmitDisabled = useMemo(() => !!Object.values(errors).filter(Boolean).length, [errors])

  return { formik, isSubmitDisabled }
}
